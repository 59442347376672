(function(){
    $.fn.menu = function(options) {
        var settings = $.extend({
            body: 'body',
            burger: '.burger',
            nav_trigger: '.nav ul li',
            break_point: 992
        })

        var menu = $(this);
        menu.wrap('<div class="navwrap"></div>');
        menu.wrap('<div class="navblock"></div>');

        var body =  $(settings.body);
        var burger =  $(settings.burger);
        var nav_trigger =  $(menu).find('ul li');
        var break_point =  settings.break_point;
        var browser_width = $(window).outerWidth();
        var nav_wrap = $('.navwrap');

        burger.addClass('__toggled');

        //burger click
        burger.click(function(event){
            event.preventDefault();
            if(body.hasClass('__navactive')){
                nav_reset();
            }else{
                nav_launch();
            };
        });

        //nav window
        var nav_window = $('<div class="navwindow"></div>');
        nav_wrap.before(nav_window);
        nav_window.click(function(event){
            nav_reset();
        });

        //nav reset
        var nav_reset = function nav_reset(){
            body.removeClass('__navactive');
            nav_wrap.find('ul').removeClass('__active');
            nav_window.hide();
        };

        //nav launch
        var nav_launch = function nav_launch(){
            nav_window.show();
            body.addClass('__navactive');
        };

        //on click, find parent
        //remove paratent active ul
        //this next/find active
        if(browser_width < break_point){
            nav_trigger.on('click', function(event){
                    event.stopPropagation();
                    if($(this).children('ul').length > 0){
                        event.preventDefault();
                        $(this).parents('ul').removeClass('__active');
                        $(this).children('ul').addClass('__active');
                    };

            });
        }
                 // nav dropdown on focus/keyboard navigation
        if (browser_width >= break_point) {
            $('.menu-item').focusin(function () {
                $(this).addClass('__active');
                $(this).children('button').attr('aria-expanded', true);
            });
            $('.menu-item').focusout(function () {
                $(this).removeClass('__active');
                $(this).children('button').attr('aria-expanded', false);
            });
            $('.menu-item').mouseover(function () {
                $(this).children('button').attr('aria-expanded', true);
            });
            $('.menu-item').mouseout(function () {
                $(this).children('button').attr('aria-expanded', false);
            });
        }

        var nav_ul = menu.find('ul li > ul')
        var view_button = $('<li class="viewall"><a href="#">View</a></li>');
        var back_button = $('<li class="backto"><a href="#">Back</a></li>');

        if(browser_width <= break_point){
            nav_ul.prepend(view_button);
            nav_ul.prepend(back_button);
        };

        var back_menu = $('.backto');
        back_menu.click(function(event){
            event.preventDefault();
            event.stopPropagation();
            $(this).parent('ul').removeClass('__active');
            $(this).parents('li').parent('ul').addClass('__active');

        });

        var view_all = $('.viewall');
        view_all.each(function () {
            var parent = $(this).closest('ul').siblings()
            if (typeof parent.attr('href') !== typeof undefined && parent.attr('href') !== false) {
                var parent_url = parent.attr('href');
                var parent_title = parent.html();
                $(this).find('a').attr('href', parent_url);
                $(this).find('a').html('View ' + parent_title);
            } else {
                $(this).remove();
            }
        });

        view_all.click(function(event){
            event.stopPropagation();
        });
    }
})();
